jQuery(document).ready(function ($) {
	jQuery(".the-slideshow").owlCarousel({
		loop: true,
		nav: true,
		dots: true,
		autoplay: true,
		mouseDrag: true,
		touchDrag: true,
		items: 1,
	});

	if (jQuery(".wrapper-tabs").length > 0 ) {

		jQuery(".wrapper-tabs .tab-menu-item a").click(function(event) {
			event.preventDefault();
			var $parents = jQuery(this).parents(".wrapper-tabs");
			$parents.find(".tab-menu-item").removeClass('active in')
			jQuery(this).parent("li").addClass('active in')

			$parents.find(".tab-content-item").removeClass('active in')
			$parents.find(jQuery(this).attr("href")).addClass("active in")

			return false;
		});
	}

	jQuery(".slider-related").owlCarousel({
		loop: true,
		nav: true,
		dots: false,
		autoplay: false,
		mouseDrag: true,
		touchDrag: true,
		responsive : {
		    0 : {
		        items: 1,
		    },
		    992 : {
		       items: 2,
		    }/*,
		    1500 : {
		        items: 3,
		    },*/
		}
	});

})
