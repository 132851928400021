function setHeight(jq_in){
    jq_in.each(function(index, elem){
        // This line will work with pure Javascript (taken from NicB's answer):
        elem.style.height = elem.scrollHeight+'px'; 
    });
}

jQuery(document).ready(function ($) {
	$('#cookiebanner button').click(function(event) {
      var dateCookie = new Date();
      dateCookie.setTime(dateCookie.getTime()+(360*24*60*60*1000));
      document.cookie = "hidecookie" + "=" + "hidden" + "; expires=" + dateCookie.toGMTString();

      $('#cookiebanner').addClass('hidden');
	});

	jQuery('.popup-disable').click(function(event) {
	  var datePop = new Date();
	  datePop.setTime(datePop.getTime()+(360*24*60*60*1000));
	  document.cookie = "hideDomainChange" + "=" + "hidden" + "; expires=" + datePop.toGMTString();

	  jQuery('#domain-popup').addClass('hidden');
	});

	jQuery(".confirm-command .actions input").click(function() {
		$nbrOfError = 0;
		event.preventDefault();

		$('.validate-this').each(function() {
		  if ($(this).val() === '') {
		  	$nbrOfError++;
		  	//console.log('text');
		  	$(this).parent().addClass('with-error');
		    $(this).parent().find('.wrong-fields').css('display','block');
			//$('.actions input').prop( "disabled", true );
		  }
		  else {
		  	$(this).parent().removeClass('with-error');
		    $(this).parent().find('.wrong-fields').css('display','none');
		    //$('.wrong-fields-message').css('display','none');
			//$('.actions input').prop( "disabled", false );
		  }
		});


		if(jQuery('#billMe').val() == 'true') {
			if(jQuery('.tva-numb').attr('maxlength') != jQuery('.tva-numb').val().length) {
			  	$nbrOfError++;
			  	$('.tva-selected').addClass('with-error');
			    $('.tva-selected').find('.wrong-fields').css('display','block');
			}
		} 
	




		$(".valid-email:required").each(function() {

			if(validator.isEmail($(this).val())) {
		  		$(this).parent().removeClass('with-error');
				$(this).parent().find('.wrong-email').css('display','none');
				$('.actions .wrong-email').css('display','none');
			}	
			else {
		  		$nbrOfError++;
		  		//console.log('email');
		  		$(this).parent().addClass('with-error');
				$(this).parent().find('.wrong-email').css('display','block');
				$('.actions .wrong-email').css('display','block');
				//console.log("false");
			}
	  	})

	  	if($('#accept-cgv').prop('checked') == false) {
	  		//console.log('pas check');
	  		$nbrOfError++;
	  		$('#accept-cgv').parent().addClass('with-error');
		    $('#accept-cgv').parent().find('.wrong-fields').css('display','block');
	  	}
	  	else {
	  		//console.log('check');
		  	$('#accept-cgv').parent().removeClass('with-error');
		    $('#accept-cgv').parent().find('.wrong-fields').css('display','none');
		    //$('.wrong-fields-message').css('display','none');
			//$('.actions input').prop( "disabled", false );
		  }

	  	//console.log($nbrOfError);

		if($nbrOfError == 0) {
			$('.confirm-command form').submit();
		}
		else {
		    $('.wrong-fields-message').css('display','block');
		    jQuery([document.documentElement, document.body]).animate({
	          scrollTop: jQuery(".confirm-command").offset().top-160
	      }, 500);
		}
	});


	// $('.valid-email').each(function() {
	// 	if(validator.isEmail($(this).val())) {
	// 		$(this).parent().find('.wrong-email').css('display','none');
	// 		$('.actions .wrong-email').css('display','none');
	// 		//$('.actions input').prop( "disabled", false );
	// 	}	
	// 	else {
	// 		$(this).parent().find('.wrong-email').css('display','block');
	// 		$('.actions .wrong-email').css('display','block');
	// 		//$('.actions input').prop( "disabled", true );
	// 		//console.log("false");
	// 	}
	// });

	// $('.validate-this').each(function() {
	//   if ($(this).val() === '') {
	//     $('.actions .wrong-fields').css('display','block');
	// 	//$('.actions input').prop( "disabled", true );
	//   }
	//   else {
	// 	$('.actions .wrong-fields').css('display','none');
	// 	//$('.actions input').prop( "disabled", false );
	//   }
	// });

	if($('#if-billme').hasClass('active') ){
		jQuery('#if-billme input, #if-billme select').each(function() {
			if(jQuery(this).hasClass('bill-required')) {
				jQuery(this).prop('required',true);
				jQuery(this).addClass('validate-this');
			}
		});    	
	}

	if(jQuery('#tvaType').val() != 'no-number' && jQuery('#tvaType').val() != '') {
		jQuery('.tva-selected').removeClass('inactive');
		jQuery('.tva-selected').addClass('active');

    	if(jQuery(this).val() == "tva-be" ) {
    		jQuery('.tva-country').val('BE0');
    		jQuery('.tva-numb').attr('maxlength','9');
    		jQuery('.tva-numb').attr('minlength','9');
    		jQuery('.tva-notes').text('Insérer les 9 chiffres (sans espace ni ponctuation) de votre numéro de TVA');
    	}
    	else if(jQuery(this).val() == "tva-lu" ) {
    		jQuery('.tva-country').val('LU');
    		jQuery('.tva-numb').attr('maxlength','8');
    		jQuery('.tva-numb').attr('minlength','8');
    		jQuery('.tva-notes').text('Insérer les 8 chiffres (sans espace ni ponctuation) de votre numéro de TVA');
    	}
    	else if(jQuery(this).val() == "tva-fr" ) {
    		jQuery('.tva-country').val('FR');
    		jQuery('.tva-numb').attr('maxlength','12');
    		jQuery('.tva-numb').attr('minlength','12');
    		jQuery('.tva-notes').text('Insérer les 12 chiffres (sans espace ni ponctuation) de votre numéro de TVA');
    	}
    	else if(jQuery(this).val() == "tva-de" ) {
    		jQuery('.tva-country').val('DE');
    		jQuery('.tva-numb').attr('maxlength','9');
    		jQuery('.tva-numb').attr('minlength','9');
    		jQuery('.tva-notes').text('Insérer les 9 chiffres (sans espace ni ponctuation) de votre numéro de TVA');
    	}

	}

	/*$('.validate-this').focusout(function() {

		$('.validate-this').each(function() {
		  if ($(this).val() === '') {
		    $(this).parent().find('.wrong-fields').css('display','block');
		    $(this).parent().find('.wrong-fields').css('display','block');
			//$('.actions input').prop( "disabled", true );
		  }
		  else {
			$(this).parent().find('.wrong-fields').css('display','none');
			//$('.actions input').prop( "disabled", false );
		  }
		})
	});*/

	// $(".valid-email").each(function() {

	// 	if(validator.isEmail($(this).val())) {
	// 		$(this).parent().find('.wrong-email').css('display','none');
	// 		$('.actions .wrong-email').css('display','none');
	// 		$('.actions input').prop( "disabled", false );
	// 	}	
	// 	else {
	// 		$(this).parent().find('.wrong-email').css('display','block');
	// 		$('.actions .wrong-email').css('display','block');
	// 		$('.actions input').prop( "disabled", true );
	// 		//console.log("false");
	// 	}
 //  	})

	/*$(".valid-email").focusout(function() {

		if(validator.isEmail($(this).val())) {
			$(this).parent().find('.wrong-email').css('display','none');
			$('.actions .wrong-email').css('display','none');
			$('.actions input').prop( "disabled", false );
		}	
		else {
			$(this).parent().find('.wrong-email').css('display','block');
			$('.actions .wrong-email').css('display','block');
			$('.actions input').prop( "disabled", true );
			//console.log("false");
		}
  	}) */

	AOS.init({
	  disable: function() {
	    var maxWidth = 800;
	    return window.innerWidth < maxWidth;
	  },
	  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
	  initClassName: 'aos-init', // class applied after initialization
	  animatedClassName: 'aos-animate', // class applied on animation
	  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
	  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
	  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
	  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
	  

	  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
	  offset: 300, // offset (in px) from the original trigger point
	  delay: 0, // values from 0 to 3000, with step 50ms
	  duration: 750, // values from 0 to 3000, with step 50ms
	  easing: 'ease-out', // default easing for AOS animations
	  once: true, // whether animation should happen only once - while scrolling down
	  mirror: true, // whether elements should animate out while scrolling past them
	  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
	});

	jQuery(".slider-home").owlCarousel({
		loop: true,
		nav: true,
		dots: false,
		autoplay: true,
		mouseDrag: true,
		touchDrag: true,
		items: 1,
	});

	jQuery(".slider-partners").owlCarousel({
		loop: true,
		nav: true,
		dots: false,
		autoplay: true,
		mouseDrag: true,
		touchDrag: true,
		responsive : {
		    0 : {
		        items: 1,
		    },
		    480 : {
		       items: 2,
		    },
		    768 : {
		        items: 3,
		    },
		    992 : {
		        items: 4,
		    },
		    1024 : {
		        items: 5,
		    }
		}
	});

	var sliderSelector = jQuery(".slider-selector")

	sliderSelector.owlCarousel({
		loop: false,
		nav: true,
		dots: false,
		autoplay: false,
		mouseDrag: true,
		touchDrag: true,
		responsive : {
		    0 : {
		        items: 4,
		    },
		   /* 480 : {
		       items: 3,
		    },
		    768 : {
		        items: 3,
		    },
		    992 : {
		        items: 4,
		    }*/
		}
	});
	
	var clickCount = 0;

	jQuery(".brand-filters button.single-brand").click(function() {

		var brandActive = jQuery(this).data('brand');
		var brandCounter = 1;
		clickCount = 0;

		jQuery('.slider-selector .owl-item button').each(function() {
			if(jQuery(this).data('brand') != brandActive) {
				jQuery(this).closest('.owl-item').addClass('hidden');
				jQuery(this).closest('.owl-item').removeClass('visible');
			}
			else {
				jQuery(this).closest('.owl-item').removeClass('hidden');
				jQuery(this).closest('.owl-item').addClass('visible');
			}
		});

		jQuery('.brand-filters button').each(function() {
			jQuery(this).removeClass('active');
		});

		jQuery(this).addClass('active');

		jQuery('.slider-visualisator figure').each(function() {
			//jQuery(this).removeClass('active');
			
			if(jQuery(this).data('brand') == brandActive && brandCounter == 1) {
				jQuery(this).addClass('active');
				brandCounter = 0;
			}
			else {
				jQuery(this).removeClass('active');
			}
		});

		jQuery('.slider-taxonomy-type button').each(function() {
			//jQuery(this).removeClass('active');
			
			if(jQuery(this).data('brand') == brandActive && brandCounter == 0) {
				jQuery(this).addClass('active');
				brandCounter = 2;
			}
			else {
				jQuery(this).removeClass('active');
			}
		});

		//jQuery('.slider-selector .owl-stage').css('transform', 'translate3d(0px, 0px, 0px)');

		var numVisible = jQuery('.slider-selector .owl-item.visible').length
		var numTotal = jQuery('.slider-selector .owl-item').length
		//console.log(numVisible);

		for (var i = 0; i < numTotal; i++) {
			sliderSelector.trigger('prev.owl.carousel');
		}

		if(numVisible < 4) {
			jQuery('.slider-selector .owl-next').addClass('disabled');
		}
		else {
			jQuery('.slider-selector .owl-next').removeClass('disabled');
		}

	});


	jQuery(".slider-selector .owl-next").click(function() {
		var numVisible = jQuery('.slider-selector .owl-item.visible').length
		clickCount ++;

		if(numVisible - 4 == clickCount) {
			//console.log('yo');
			jQuery('.slider-selector .owl-next').addClass('disabled');
		}
	});

	jQuery(".slider-selector .owl-prev").click(function() {
		//console.log(clickCount);
		clickCount --;
	});


	jQuery(".brand-filters button.all-brands").click(function() {

		jQuery('.slider-selector .owl-item button').each(function() {
			jQuery(this).closest('.owl-item').removeClass('hidden');
		});

		jQuery('.slider-visualisator figure').each(function() {
			jQuery(this).removeClass('active');
		});


		jQuery('.brand-filters button').each(function() {
			jQuery(this).removeClass('active');
		});

		jQuery(this).addClass('active');
		jQuery('.slider-visualisator figure:first').addClass('active');
		jQuery('.slider-taxonomy-type button:first').addClass('active');

	});


	jQuery(".slider-taxonomy-type .owl-item button").click(function() {

		var slideActive = jQuery(this).data('slide');

		jQuery('.slider-visualisator figure').each(function() {
			if(jQuery(this).data('slide') == slideActive) {
				jQuery(this).addClass('active');
			}
			else {
				jQuery(this).removeClass('active');
			}
		});

		jQuery('.slider-taxonomy-type button').each(function() {
			jQuery(this).removeClass('active');
		});

		jQuery(this).addClass('active');


	});

	jQuery("main.birthlist-single .the-list ul li:not(.all-gone, .cat-title, .cant-order)").click(function() {
		var productDetail = [];

		var product = jQuery(this);

		productDetail['productName'] = product.find('.name').text().trim();
		productDetail['remaining'] = product.find('.remaining').text().trim();
		productDetail['price'] = product.find('.price span').text().trim();
		productDetail['productImgSrc'] = product.find('img').attr('src');
		productDetail['itemCode'] = product.find('.product-itemCode').attr('value');
		productDetail['quantityOrigin'] = product.find('.product-quantity-origin').attr('value');
		productDetail['quantityReserved'] = product.find('.product-quantity-reserved').attr('value');

		//console.log(productDetail);

		jQuery('#birthlist-cart .input-name').attr('value', productDetail['productName']);
		jQuery('#birthlist-cart .title').text(productDetail['productName']);
		jQuery('#birthlist-cart .input-singlePrice').attr('value', productDetail['price']);
		jQuery('#birthlist-cart .input-price').attr('value', productDetail['price']);
		jQuery('#birthlist-cart .input-itemCode').attr('value', productDetail['itemCode']);
		jQuery('#birthlist-cart .input-quantityOrigin').attr('value', productDetail['quantityOrigin']);
		jQuery('#birthlist-cart .input-quantityReserved').attr('value', productDetail['quantityReserved']);
		jQuery('#birthlist-cart .product-img').attr('src', productDetail['productImgSrc']);

		jQuery('#birthlist-cart .input-quantity').children().remove().end()

		for (var i = 1; i <= productDetail['remaining']; i++) {
			jQuery('#birthlist-cart .input-quantity').append(
				jQuery('<option>', {value:i, text:i})
			);	
		}

		jQuery('#birthlist-cart').removeClass('hidden');

		setHeight(jQuery('.input-name'));
	});

	jQuery("#birthlist-cart .input-quantity").change(function(){

        var selectedQuantity = jQuery(this).children("option:selected").val();
        var newPrice = parseFloat(selectedQuantity) * parseFloat(jQuery('#birthlist-cart .input-singlePrice').attr('value'));
        var newPrice = newPrice;
        jQuery('#birthlist-cart .input-price span').attr('value', newPrice);

    });

	jQuery(".popup-close, .popup-bg, .popup-content .cancel").click(function() {
		jQuery('#birthlist-cart').addClass('hidden');
	});

    jQuery('.confirm').click(function() {
    	var name = jQuery(this).attr('data-name');
    	var message = "Êtes-vous certain de vouloir supprimer de votre panier l'article suivant: " + name + " ?";
        return window.confirm(message);
    });

    jQuery('.empty-cart-confirm').click(function() {
    	return window.confirm("Êtes-vous certain de vouloir supprimer votre panier d'achat ? Cette action est irréversible...");
    });

    jQuery('.toggle-grid').click(function() {
    	jQuery('section.the-list ul').removeClass('list-mode');
    	jQuery('section.the-list ul').addClass('grid-mode');
    });

    jQuery('.toggle-list').click(function() {
    	jQuery('section.the-list ul').removeClass('grid-mode');
    	jQuery('section.the-list ul').addClass('list-mode');
    });

    jQuery('#billMe').on("change",function() {
    	//jQuery('#if-billme').toggleClass('active inactive');
    	if(jQuery('#billMe').children('option:selected').val() == 'true') {
    		jQuery('#if-billme').removeClass('inactive');
    		jQuery('#if-billme').addClass('active');

			$('#if-billme .validate-this').each(function() {
			  if ($(this).val() === '') {
			    $(this).parent().find('.wrong-fields').css('display','block');
				//$('.actions input').prop( "disabled", true );
			  }
			  else {
			    $(this).parent().find('.wrong-fields').css('display','none');
			    //$('.wrong-fields-message').css('display','none');
				//$('.actions input').prop( "disabled", false );
			  }
			});	

    		jQuery('#if-billme input, #if-billme select').each(function() {
				if(jQuery(this).hasClass('bill-required')) {
					jQuery(this).prop('required',true);
					jQuery(this).addClass('validate-this');
				}
    		});    
    	}
    	else {
    		jQuery('#if-billme').removeClass('active');
    		jQuery('#if-billme').addClass('inactive');

    		jQuery('#if-billme input, #if-billme select').each(function() {
					jQuery(this).prop('required',false);
					jQuery(this).removeClass('validate-this');
					console.log('plutôt là');
    		});
    	}
    });

    jQuery('.tva-numb').on("change", function() {
    	jQuery('.tvaNumber').val(jQuery('.tva-country').val() + jQuery('.tva-numb').val());
    });

    jQuery('#tvaType').on("change",function() {
		jQuery('.tva-numb').val('');
    	jQuery('.tva-numb').attr('maxlength','0');
    	jQuery('.tva-numb').attr('minlength','0');
		jQuery('.tva-country').val('');
		jQuery('.tvaNumber').val('');

    	if(jQuery(this).val() == "no-number" ) {
    		jQuery('.tva-selected').removeClass('active');
    		jQuery('.tva-selected').addClass('inactive');
    	}
    	else {
    		jQuery('.tva-selected').removeClass('inactive');
    		jQuery('.tva-selected').addClass('active');
    	}

    	// IF TVA NUMBER

    	if(jQuery(this).val() == "tva-be" ) {
    		jQuery('.tva-country').val('BE0');
    		jQuery('.tva-numb').attr('maxlength','9');
    		jQuery('.tva-numb').attr('minlength','9');
    		jQuery('.tva-notes').text('Insérer les 9 chiffres (sans espace ni ponctuation) de votre numéro de TVA');
    	}
    	else if(jQuery(this).val() == "tva-lu" ) {
    		jQuery('.tva-country').val('LU');
    		jQuery('.tva-numb').attr('maxlength','8');
    		jQuery('.tva-numb').attr('minlength','8');
    		jQuery('.tva-notes').text('Insérer les 8 chiffres (sans espace ni ponctuation) de votre numéro de TVA');
    	}
    	else if(jQuery(this).val() == "tva-fr" ) {
    		jQuery('.tva-country').val('FR');
    		jQuery('.tva-numb').attr('maxlength','12');
    		jQuery('.tva-numb').attr('minlength','12');
    		jQuery('.tva-notes').text('Insérer les 12 chiffres (sans espace ni ponctuation) de votre numéro de TVA');
    	}
    	else if(jQuery(this).val() == "tva-de" ) {
    		jQuery('.tva-country').val('DE');
    		jQuery('.tva-numb').attr('maxlength','9');
    		jQuery('.tva-numb').attr('minlength','9');
    		jQuery('.tva-notes').text('Insérer les 9 chiffres (sans espace ni ponctuation) de votre numéro de TVA');
    	}
    });

    if (jQuery(window).width() > 740) {
    	jQuery('section.the-list ul').toggleClass('list-mode grid-mode');
    }

})

/*jQuery(window).on('resize', function(){
    if (jQuery(window).width() > 740) {
    	jQuery('section.the-list ul').removeClass('list-mode');
    	jQuery('section.the-list ul').addClass('grid-mode');
    }
    else {
    	jQuery('section.the-list ul').removeClass('grid-mode');
    	jQuery('section.the-list ul').addClass('list-mode');
    }
});*/

jQuery(".popup-close, .popup-bg").click(function() {
	jQuery('#domain-popup').addClass('hidden');
});